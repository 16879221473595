// CreatePoll.jsx

import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import './CreatePoll.css';
import UpgradeModal from './UpgradeModal';
import PreviewPoll from './PreviewPoll';
import './datepicker-dark-theme.css'; // Import your custom dark theme CSS
import captchaImage from '../assets/captcha.png';
import Info from './Info';
import logger from './logger';
import AiIcon from '../assets/ai.png';
import LoadingIcon from '../assets/loading-small.png';
import ImageCropperModal from './ImageCropperModal';
import ColorWheelBuilder from './ColorWheelBuilder'; // Import the modified component
import BrandAdMockup from './BrandAdMockup'; // Import the modified component
import AdType1 from '../assets/AdType1.png';
import AdType2 from '../assets/AdType2.png';
import AdType3 from '../assets/AdType3.png';






const BrandCreatePoll = ({ isLoggedIn, userId, username, onCreate, subscriptionStatus,     initialSelectedCampaign // Renamed prop
}) => {
  const [newPollQuestion, setNewPollQuestion] = useState('');
  const [newPollLabels, setNewPollLabels] = useState(['Agree', 'Neutral', 'Disagree']);
  const [newPollCategory, setNewPollCategory] = useState('Technology');
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [redirectLink, setRedirectLink] = useState('');
  const [redirectMessage, setRedirectMessage] = useState('');
  const [redirectMessageError, setRedirectMessageError] = useState('');
  const [voteAuthenticationMethod, setVoteAuthenticationMethod] = useState(1);
  const [pollQuestionError, setPollQuestionError] = useState('');
  const [useCustomLabels, setUseCustomLabels] = useState(false);
  const [customLabels, setCustomLabels] = useState(['Agree', 'Neutral', 'Disagree']);
  const [customConfidenceLabels, setCustomConfidenceLabels] = useState([
    ['Sub-Answer 1', 'Sub-Answer 2', 'Sub-Answer 3'],
    ['Sub-Answer 1', 'Sub-Answer 2', 'Sub-Answer 3'],
    ['Sub-Answer 1', 'Sub-Answer 2', 'Sub-Answer 3'],
  ]);

  const [visibility, setVisibility] = useState('Private'); // Default to Private
  const [logo, setLogo] = useState(null);
  const [tempLogoUrl, setTempLogoUrl] = useState('/logo-poll.png');
  const textareaRef = useRef(null);
  const [fillBackground, setFillBackground] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [imageAction, setImageAction] = useState(0);
  const [roundCorners, setRoundCorners] = useState(false);
  const [useSchedule, setUseSchedule] = useState(false);
  const [startDate, setStartDate] = useState(new Date()); // Default to "Now"
  const [endDate, setEndDate] = useState(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)); // Default to 7 days from now
  const [useCaptcha, setUseCaptcha] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const linkConfidenceLevelsRef = useRef(true);
  const [mainOptionAnimations, setMainOptionAnimations] = useState([false, false, false]); // For three main options
  const [confidenceLevelAnimations, setConfidenceLevelAnimations] = useState(
    customConfidenceLabels.map(() => [false, false, false, false, false]) // Initialize animation states for confidence levels
  );
  const [forceUpdate, setForceUpdate] = useState(false); // A state to force re-render
  const [isCropping, setIsCropping] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const labelRefs = useRef([]); // Create refs for each label input
  const confidenceRefs = useRef([]); // Refs for confidence level inputs
  const [isAdvertising, setIsAdvertising] = useState(false);

  const [keywords, setKeywords] = useState([]);
  const [currentKeyword, setCurrentKeyword] = useState('');
  const [budget, setBudget] = useState('');

  const [ads, setAds] = useState([
    { description: '', link: '' }, // Default one ad
]);

// Add this state definition near the top of your component, alongside other useState hooks
const [selectedCampaign, setSelectedCampaign] = useState('');

const [campaigns, setCampaigns] = useState([]);


useEffect(() => {
  if (initialSelectedCampaign) {
      setSelectedCampaign(initialSelectedCampaign); // Initialize state from prop
  }
}, [initialSelectedCampaign]);


  const pollOptions = [
    ['Yes', 'Maybe', 'No'],
    ['Agree', 'Neutral', 'Disagree'],
    ['Positive', 'Neutral', 'Negative'],
    ['Approve', 'Indifferent', 'Disapprove'],
    ['Good', 'Average', 'Bad'],
    ['Support', 'Neutral', 'Oppose'],
    ['True', 'Unsure', 'False'],
    ['High', 'Medium', 'Low'],
    ['Like', 'Neutral', 'Dislike'],
    ['Happy', 'Neutral', 'Unhappy'],
    ['Satisfied', 'Neutral', 'Unsatisfied'],
  ];

  const confidenceMapping = {
    "Yes, Maybe, No": [
      ["Weak", "Unsure", "Moderate", "Strong", "Confident"],
      ["Weak", "Unsure", "Moderate", "Strong", "Confident"],
      ["Weak", "Unsure", "Moderate", "Strong", "Confident"],
    ],
    "Agree, Neutral, Disagree": [
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
    ],
    "Positive, Neutral, Negative": [
      ["Marginally", "Slightly", "Moderately", "Strongly", "Extremely"],
      ["Marginally", "Slightly", "Moderately", "Strongly", "Extremely"],
      ["Marginally", "Slightly", "Moderately", "Strongly", "Extremely"],
    ],
    "Approve, Indifferent, Disapprove": [
      ["Minimally", "Slightly", "Somewhat", "Moderately", "Strongly"],
      ["Minimally", "Slightly", "Somewhat", "Moderately", "Strongly"],
      ["Minimally", "Slightly", "Somewhat", "Moderately", "Strongly"],
    ],
    "Good, Average, Bad": [
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
    ],
    "Support, Neutral, Oppose": [
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
    ],
    "High, Medium, Low": [
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Extremely"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Extremely"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Extremely"],
    ],
    "Like, Neutral, Dislike": [
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
    ],
    "Happy, Neutral, Unhappy": [
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Extremely"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Extremely"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Extremely"],
    ],
    "Satisfied, Neutral, Unsatisfied": [
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
    ],
    "True, Unsure, False": [
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
    ],
  };

  const categories = [
    'Technology',
    'Health',
    'Education',
    'Entertainment',
    'Politics',
    'Sports',
    'Lifestyle',
    'Environment',
    'Fashion',
    'Services',
    'Products',
    'Travel',
    'Food',
    'Business',
  ];

  // Function to ensure refs are properly initialized
  const ensureConfidenceRefsInitialized = (optionIndex) => {
    if (!confidenceRefs.current[optionIndex]) {
      confidenceRefs.current[optionIndex] = [];
    }
  };

  // Function to convert HSL to RGB
  function hslToRgb(h, s, l) {
    let a = s * Math.min(l, 1 - l);
    const f = (n) => {
      const k = (n + h / 30) % 12;
      return l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    };
    return [Math.round(f(0) * 255), Math.round(f(8) * 255), Math.round(f(4) * 255)];
  }

  // Color wheel options for the preview
  const colorWheelOptions = customLabels.map((label, optionIndex) => {
    const finalLabel = label.trim() !== '' ? label.trim() : `Option ${optionIndex + 1}`;

    // Ensure customConfidenceLabels[optionIndex] exists before mapping over it
    const finalConfidenceLevels = (customConfidenceLabels[optionIndex] || []).map(
      (confLabel, labelIndex) => {
        const trimmedConfLabel = typeof confLabel === 'string' ? confLabel.trim() : '';
        return trimmedConfLabel !== '' ? trimmedConfLabel : `Confidence ${labelIndex + 1}`;
      }
    );

    return {
      label: finalLabel,
      confidenceLevels: finalConfidenceLevels,
    };
  });

  // Handler for node click in the color wheel
  const handleNodeClick = (optionIndex, levelIndex = null) => {
    if (levelIndex === null) {
      const targetField = labelRefs.current[optionIndex];
      if (targetField) {
        targetField.scrollIntoView({ behavior: 'smooth', block: 'center' });
        targetField.focus();
      }
    } else {
      const targetField = confidenceRefs.current[optionIndex]?.[levelIndex];
      if (targetField) {
        targetField.scrollIntoView({ behavior: 'smooth', block: 'center' });
        targetField.focus();
      }
    }
  };




   // Updated function to handle API response and update state
   const handleGeneratePollOptions = async () => {
    if (!newPollQuestion.trim()) {
      alert('Poll question cannot be empty.');
      return;
    }
  
    setIsGenerating(true); // Show spinner and hide button/checkbox
    try {
      const response = await fetch('/api/generate-poll-options', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question: newPollQuestion }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch poll options.');
      }
  
      const data = await response.json();
  
      if (
        data.answers &&
        data.answers.customLabels &&
        data.answers.customConfidenceLabels &&
        data.answers.keywords // Check for keywords in the response
      ) {
        // The AI successfully generated options
        setNewPollLabels(data.answers.customLabels);
        setCustomLabels(data.answers.customLabels);
        setUseCustomLabels(true); // Enable custom labels automatically
        setCustomConfidenceLabels(data.answers.customConfidenceLabels || []);
  
        // Assuming `setKeywords` is the state setter for the keyword list
        setKeywords(data.answers.keywords || []);
      } else if (data.answers?.explanation && data.answers?.recommendations) {
        // The AI returned an explanation and recommendations
        alert(
          `Explanation: ${data.answers.explanation}\n\nRecommendations:\n- ${data.answers.recommendations.join(
            '\n- '
          )}`
        );
      } else {
        console.error('Unexpected response structure:', data);
        alert('An unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      console.error('Error generating poll options:', error);
      alert('An error occurred while generating poll options. Please try again.');
    } finally {
      setIsGenerating(false); // Hide spinner and show button/checkbox
    }
  };
  
  
  
  
  
  


  // CustomInput component for date pickers
  const CustomInput = React.forwardRef(({ value, onClick, label }, ref) => (
    <div className="custom-date-input" onClick={onClick} ref={ref}>
      {label}: {value || `Select ${label.toLowerCase()}`}
    </div>
  ));

  // Calculate dynamic colors function
  const calculateDynamicColors = (confidenceLevels, baseHue) => {
    const minSaturation = 50; // Adjust to your preference
    const maxSaturation = 100;
    const step =
      confidenceLevels.length > 1 ? (maxSaturation - minSaturation) / (confidenceLevels.length - 1) : 0;

    return confidenceLevels.map((_, index) => {
      const saturation = minSaturation + step * index;
      return `hsl(${baseHue}, ${saturation}%, 50%)`; // Adjust lightness as needed
    });
  };

  // Handle image upload
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedImage(reader.result); // Show image in the cropping modal
      setIsCropping(true); // Show cropping modal
      setRoundCorners(true); // Turn on rounded corners by default after image upload
    };
    reader.readAsDataURL(file);
  };

  // Handle crop complete
  const handleCropComplete = (croppedImage) => {
    setLogo(croppedImage); // Use the cropped image
    setTempLogoUrl(URL.createObjectURL(croppedImage)); // Preview the cropped image
    setIsCropping(false); // Hide cropping modal
  };

  // Handle form submission to create poll
  const handleConfirmCreatePoll = async () => {
    const baseUrl = process.env.REACT_APP_API_URL || window.location.origin; // Use environment variable or fallback to current origin
    logger.log('Starting poll creation process');
    setIsLoading(true);

    // Check if the poll question is provided
    if (!newPollQuestion.trim()) {
      setPollQuestionError('Poll question is required.');
      setIsLoading(false);
      return;
    } else {
      setPollQuestionError('');
    }

    // Set default creator and creatorUsername based on the login status
    let finalCreator = userId;
    let finalCreatorUsername = username;
    let isTemporary = false; // Track if the poll is temporary

    // If the user is not logged in, assign anonymous values
    if (!isLoggedIn) {
      finalCreator = '66f2fc9406a25b4676dcc980'; // Anonymous creator ID
      finalCreatorUsername = 'Anonymous';
      isTemporary = true; // Set temporary poll to true
    }

    // Validate redirect message length
    if (redirectMessage.length > 70) {
      setRedirectMessageError('Redirect message cannot exceed 70 characters.');
      setIsLoading(false);
      return;
    }

    // Format redirect link
    let formattedRedirectLink = redirectLink;
    if (redirectLink && !redirectLink.startsWith('http://') && !redirectLink.startsWith('https://')) {
      formattedRedirectLink = `https://${redirectLink}`;
    }

    // Prepare final poll labels and confidence levels
    // Update finalLabels to ensure no empty labels
    let finalLabels = useCustomLabels
      ? customLabels.map((label, i) => {
          const trimmedLabel = label.trim();
          return trimmedLabel !== '' ? trimmedLabel : `Option ${i + 1}`;
        })
      : newPollLabels;

    // Update finalConfidenceLabels to ensure no empty confidence levels
    let finalConfidenceLabels = customConfidenceLabels.map((confidenceArray, optionIndex) =>
      confidenceArray.map((confLabel, confIndex) => {
        const trimmedConfLabel = typeof confLabel === 'string' ? confLabel.trim() : '';
        return trimmedConfLabel !== '' ? trimmedConfLabel : `Confidence ${confIndex + 1}`;
      })
    );

    // Prepare form data for poll creation
    const formData = new FormData();
    formData.append('question', newPollQuestion);
    formData.append('labels', JSON.stringify(finalLabels));
    formData.append('confidenceLabels', JSON.stringify(finalConfidenceLabels));
    formData.append('category', newPollCategory);
    formData.append('creator', finalCreator); // Use finalCreator
    formData.append('creatorUsername', finalCreatorUsername); // Use finalCreatorUsername
    formData.append('redirectLink', formattedRedirectLink);
    formData.append('redirectMessage', redirectMessage);
    formData.append('voteAuthenticationMethod', voteAuthenticationMethod);
    formData.append('visibility', visibility);
    formData.append('logoFill', fillBackground);
    formData.append('imageAction', imageAction);
    formData.append('roundedCorners', roundCorners);
    formData.append('useCaptcha', useCaptcha);
    formData.append('isTemporary', isTemporary); // Include isTemporary field
    formData.append('isAdvertising', isAdvertising);
    formData.append('pollType', 'Brand'); // Set pollType as "Brand"
    formData.append('budget', budget || 0); // Default to 0 if no budget is specified
    formData.append('ads', JSON.stringify(ads));

    



    // Handle logo upload
    if (logo) {
      formData.append('logo', logo);
    }

    // Handle poll scheduling data
    formData.append('scheduledPoll', useSchedule);
    if (useSchedule) {
      formData.append('startDate', startDate.toISOString());
      formData.append('endDate', endDate.toISOString());

      const now = new Date();
      const pollActive = startDate <= now && (!endDate || endDate >= now);
      formData.append('pollActive', pollActive);
    } else {
      formData.append('pollActive', true);
    }

    // Ensure start and end date are valid
    if (startDate && endDate && startDate > endDate) {
      alert('End date cannot be before the start date.');
      setIsLoading(false);
      return;
    }

    // Make the API call to create the poll
    fetch('/polls', {
      method: 'POST',
      headers: {
        Authorization: isLoggedIn ? `Bearer ${localStorage.getItem('token')}` : '',
      },
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        const fullPollLink = `${baseUrl}/poll/${data.hash}`; // Construct full URL

        onCreate({
          pollLink: fullPollLink, // Use full URL here
          isTemporary: isTemporary,
          selectedCampaign, // Pass the selected campaign back to the parent
        });

        // Reset form fields
        setNewPollQuestion('');
        setNewPollLabels(['Agree', 'Neutral', 'Disagree']);
        setNewPollCategory('Technology');
        setRedirectLink('');
        setRedirectMessage('');
        setTempLogoUrl('/logo-poll.png');
        setLogo(null);
        return fetch(`/campaigns/${selectedCampaign}/addPoll`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ pollId: data._id }),
        });
      })
      .catch((error) => {
        logger.error('Error adding poll:', error);
        alert('An error occurred while creating the poll. Please try again.');
      })
      .finally(() => {
        setIsLoading(false);
      });

      
      
  };

  // Handle custom label changes
  const handleCustomLabelChange = (index, value) => {
    if (value.length <= 50) {
      setCustomLabels((prevLabels) => {
        const updatedLabels = [...prevLabels];
        updatedLabels[index] = value;
        return updatedLabels;
      });
    }
  };

  const handleBlur = (index) => {
    setCustomLabels((prevLabels) => {
      const updatedLabels = [...prevLabels];
      // If the field is left empty, set it to the default "Option #"
      if (updatedLabels[index].trim() === '') {
        updatedLabels[index] = `Option ${index + 1}`;
      }
      return updatedLabels;
    });
  };

  // Handle adding main opinions
  const handleAddMainOpinion = () => {
    if (customLabels.length >= 12) return;

    setCustomLabels((prevLabels) => [...prevLabels, `Option ${prevLabels.length + 1}`]);
    setCustomConfidenceLabels((prevConfidenceLabels) => [
      ...prevConfidenceLabels,
      ['Confidence 1', 'Confidence 2', 'Confidence 3'],
    ]);
  };

  const handleDeleteMainOpinion = (index) => {
    if (customLabels.length <= 3) return;
    setCustomLabels((prevLabels) => prevLabels.filter((_, i) => i !== index));
    setCustomConfidenceLabels((prevConfidenceLabels) => prevConfidenceLabels.filter((_, i) => i !== index));
  };

  // Handle custom confidence label changes
  const handleCustomConfidenceLabelChange = (optionIndex, labelIndex, value) => {
    if (value.length <= 40) {
      const updatedLabels = [...customConfidenceLabels];

      if (linkConfidenceLevelsRef.current) {
        updatedLabels.forEach((_, i) => {
          updatedLabels[i][labelIndex] = value;
        });
      } else {
        updatedLabels[optionIndex][labelIndex] = value;
      }

      setCustomConfidenceLabels(updatedLabels);
    }
  };

  // Handle adding confidence levels
  const handleAddConfidenceLevel = (optionIndex) => {
    linkConfidenceLevelsRef.current = false;

    setCustomConfidenceLabels((prevConfidenceLabels) => {
      const updatedConfidenceLabels = [...prevConfidenceLabels];
      if (updatedConfidenceLabels[optionIndex].length < 5) {
        updatedConfidenceLabels[optionIndex] = [...updatedConfidenceLabels[optionIndex], ''];
      }
      return updatedConfidenceLabels;
    });
  };

  const handleDeleteConfidenceLevel = (optionIndex, labelIndex) => {
    linkConfidenceLevelsRef.current = false;

    setCustomConfidenceLabels((prevConfidenceLabels) => {
      const updatedConfidenceLabels = [...prevConfidenceLabels];
      if (updatedConfidenceLabels[optionIndex]) {
        updatedConfidenceLabels[optionIndex].splice(labelIndex, 1);
      }
      return updatedConfidenceLabels;
    });
  };

  // Update confidence labels when useCustomLabels changes
  // Update confidence labels when useCustomLabels changes
// Update confidence labels when useCustomLabels changes
// Update confidence labels when useCustomLabels changes or number of customLabels changes
useEffect(() => {
  if (useCustomLabels) {
    setCustomConfidenceLabels((prevConfidenceLabels) => {
      const prevLength = prevConfidenceLabels.length;
      const newLength = customLabels.length;

      if (prevLength !== newLength) {
        // Number of customLabels changed, reset confidence labels
        return customLabels.map(() => ['Marginally', 'Somewhat', 'Strongly']);
      } else {
        // Keep existing confidence labels
        return prevConfidenceLabels;
      }
    });
  } else {
    // If custom answers are disabled, reset confidence labels to defaults for selected preset
    const selectedLabels = newPollLabels.join(', ');
    const correspondingConfidenceLabels = confidenceMapping[selectedLabels];

    setCustomConfidenceLabels(
      correspondingConfidenceLabels
        ? correspondingConfidenceLabels.map((conf) => [...conf])
        : [
          ['Marginally', 'Somewhat', 'Strongly'],
          ['Marginally', 'Somewhat', 'Strongly'],
          ['Marginally', 'Somewhat', 'Strongly'],
          ]
    );
  }
}, [useCustomLabels, customLabels.length, newPollLabels]);



useEffect(() => {
  const fetchCampaigns = async () => {
    try {
      const response = await fetch(`/campaigns?creator=${userId}`);
      if (!response.ok) throw new Error('Failed to fetch campaigns');
      const data = await response.json();
      setCampaigns(data.campaigns); // Update the campaigns state
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };
  fetchCampaigns();
}, [userId]);


  // Update confidence labels when customLabels change
  useEffect(() => {
    if (useCustomLabels) {
      setCustomConfidenceLabels((prevConfidenceLabels) => {
        const updatedConfidenceLabels = [...prevConfidenceLabels];
        const currentLength = updatedConfidenceLabels.length;
        const desiredLength = customLabels.length;

        if (currentLength < desiredLength) {
          // Add new confidence levels for added options
          for (let i = currentLength; i < desiredLength; i++) {
            updatedConfidenceLabels.push(['Marginally', 'Somewhat', 'Strongly']);
          }
        } else if (currentLength > desiredLength) {
          // Remove confidence levels for deleted options
          updatedConfidenceLabels.splice(desiredLength);
        }

        return updatedConfidenceLabels;
      });
    }
  }, [customLabels, useCustomLabels]);

  // Handle text area auto-resize
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [newPollQuestion]);



    // Set default value of isAdvertising based on subscription status
    useEffect(() => {
      if (subscriptionStatus === 'Enterprise') {
        setIsAdvertising(true); // Enable by default for Enterprise
      } else {
        setIsAdvertising(false); // Disable for non-Enterprise users
      }
    }, [subscriptionStatus]);


  useEffect(() => {
    setVisibility('Private'); // Always keep visibility as Private
}, []);


  useEffect(() => {
    if (isAdvertising) {
        setVoteAuthenticationMethod(1); // Force one vote per person
    }
}, [isAdvertising]);

   // Prepare finalLabels and finalConfidenceLabels for the preview
   let finalLabels = useCustomLabels
   ? customLabels.map((label, i) => {
       const trimmedLabel = label.trim();
       return trimmedLabel !== '' ? trimmedLabel : `Option ${i + 1}`;
     })
   : newPollLabels;

  let finalConfidenceLabels = customConfidenceLabels.map((confidenceArray, optionIndex) =>
    confidenceArray.map((confLabel, confIndex) => {
      const trimmedConfLabel = typeof confLabel === 'string' ? confLabel.trim() : '';
      return trimmedConfLabel !== '' ? trimmedConfLabel : `Sub Option ${confIndex + 1}`;
    })
  );

  // Prepare pollConfig for the preview
  const pollConfig = {
    question: newPollQuestion.trim() !== '' ? newPollQuestion : 'Enter poll question',
    labels: finalLabels,
    confidenceLabels: finalConfidenceLabels,
    category: newPollCategory,
    creator: userId,
    creatorUsername: username,
    redirectLink,
    redirectMessage,
    voteAuthenticationMethod,
    visibility,
    logo: tempLogoUrl, // Use the temporary logo URL
    logoFill: fillBackground,
    imageAction,
    roundedCorners: roundCorners,
  };


  const handleAdImageUpload = async (e, index) => {
    const file = e.target.files[0];
    if (!file) return;
  
    const formData = new FormData();
    formData.append("file", file);
  
    try {
      const response = await fetch("/upload-ad-image", {
        method: "POST",
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error("Failed to upload ad image.");
      }
  
      const { imageUrl } = await response.json();
      console.log("Uploaded image URL:", imageUrl);
  
      const updatedAds = [...ads];
      updatedAds[index].image = imageUrl; // Update the ad image with the server-provided URL
      setAds(updatedAds);
    } catch (error) {
      console.error("Error uploading ad image:", error);
    }
  };
  
  

  

  return (
    <div className="master">

<div className="campaign-dropdown">
  <select
    id="campaign-select"
    value={selectedCampaign || ''}
    onChange={(e) => setSelectedCampaign(e.target.value)}
    className="campaign-select"
  >
    <option value="" disabled>
      Campaign: Select a Campaign
    </option>
    {campaigns.map((campaign) => (
      <option key={campaign._id} value={campaign._id}>
        Campaign: {campaign.campaignName}
      </option>
    ))}
  </select>
</div>

    
    <div className="create-poll-page">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-content">
            <div className="loading-text">Creating Poll</div>
            <div className="loading-spinner"></div>
          </div>
        </div>
      )}





      <div className="create-poll-and-preview">
        <div className="create-poll-content">

        <div className="custom-switch">
            <input
                type="checkbox"
                id="isAdvertising"
                checked={isAdvertising}
                onChange={(e) => setIsAdvertising(e.target.checked)}
                disabled={subscriptionStatus !== 'Enterprise'} // Disable for Free users
            />
            <label htmlFor="isAdvertising" className="switch-label">
                <span className="switch-slider" />
            </label>
            <span className="switch-text">
                {isAdvertising ? 'Promotions Enabled' : 'Promotions Disabled'}
            </span>
        </div>



          {/* Image Upload Section */}
          <div className="image-upload-container">
            <div className={`logo-background ${fillBackground ? 'filled' : ''}`}>
              <img
                src={logo ? tempLogoUrl : '/logo.png'} // Default to /logo.png if no logo is uploaded
                alt="Poll Logo"
                className="poll-logo"
                onClick={() => document.getElementById('logo-upload').click()}
                style={{
                  borderRadius: roundCorners ? '10px' : '0px',
                }}
              />
            </div>
            <button
              type="button"
              className="upload-logo-button"
              onClick={() => document.getElementById('logo-upload').click()}
            >
              Upload Image
            </button>
            <div className="image-options-small-text">Optimum Size - 16:9</div>
            <input
              type="file"
              accept="image/*"
              id="logo-upload"
              style={{ display: 'none' }}
              onChange={handleImageUpload}
            />

            {isCropping && selectedImage && (
              <ImageCropperModal
                imageSrc={selectedImage}
                onCropComplete={handleCropComplete}
                onCancel={() => setIsCropping(false)}
              />
            )}
          </div>

          {logo && (
            <div className="image-options">
              <h3 className="image-options-title">Image Options</h3>
              <div className="custom-checkbox">
                <input
                  type="checkbox"
                  id="roundCorners"
                  checked={roundCorners}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setRoundCorners(true);
                      setFillBackground(false);
                    } else {
                      setRoundCorners(false);
                    }
                  }}
                />
                <label htmlFor="roundCorners">Round Corners</label>
              </div>

              <div className="custom-checkbox">
                <input
                  type="checkbox"
                  id="fillBackground"
                  checked={fillBackground}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setRoundCorners(false);
                    }
                    setFillBackground(e.target.checked);
                  }}
                />
                <label htmlFor="fillBackground">Fill Transparent Background</label>
              </div>
              <h4 className="image-action-title">Image Action</h4>
              <div className="radio-group">
                <label className="radio-label">
                  <input
                    type="radio"
                    name="imageAction"
                    value={0}
                    checked={imageAction === 0}
                    onChange={() => setImageAction(0)}
                  />
                  Link to Poll
                </label>
                <label className="radio-label">
                  <input
                    type="radio"
                    name="imageAction"
                    value={1}
                    checked={imageAction === 1}
                    onChange={() => setImageAction(1)}
                  />
                  Expand Image
                </label>
              </div>
            </div>
          )}

          {/* Poll Question Input */}
          <div className="textarea-container">
            <textarea
              ref={textareaRef}
              placeholder="Enter poll question..."
              value={newPollQuestion}
              onChange={(e) => {
                setNewPollQuestion(e.target.value);
                if (e.target.value.trim()) {
                  setPollQuestionError(''); // Clear the error if the question is filled
                }
              }}
              className={`poll-question-input ${pollQuestionError ? 'input-error' : ''}`} // Add error class if there's an error
              rows={1}
              onInput={(e) => {
                e.target.style.height = 'auto';
                e.target.style.height = `${e.target.scrollHeight}px`;
              }}
              readOnly={isTyping}
            />
            {pollQuestionError && (
              <p className="error-message" style={{ color: 'red' }}>
                {pollQuestionError}
              </p>
            )}
          </div>


          <div className="generate-poll-options">
  {isGenerating ? (
    <div className="loading-spinner-container">
      <img
        src={LoadingIcon}
        alt="Loading..."
        className="loading-spinner"
        style={{ width: '40px', height: '40px' }}
      />
    </div>
  ) : (
    <>

 <button
  type="button"
  className="generate-button"
  onClick={handleGeneratePollOptions}
  style={{
    backgroundColor: '#0252ff',
    color: 'white',
    border: 'none',
    padding: '10px 15px',
    borderRadius: '5px',
    cursor: 'pointer',
    margin: '10px 0',
    display: 'flex', // Ensures the text and image are aligned horizontally
    alignItems: 'center', // Centers items vertically
    justifyContent: 'space-between', // Adds spacing between the text and icon
    gap: '10px', // Adds space between text and icon
    marginBottom: '25px',
  }}
>
  Generate Options
  <img
    src={AiIcon}
    alt="AI Icon"
    style={{
      width: '20px', // Adjust the icon size
      height: '20px',
    }}
  />
</button> 





      <div className="custom-checkbox">
        <input
          type="checkbox"
          id="useCustomLabels"
          checked={useCustomLabels}
          onChange={(e) => setUseCustomLabels(e.target.checked)}
        />
        <label htmlFor="useCustomLabels">Use Custom Options</label>
      </div>
    </>
  )}





{/* Display the first 3 preset options */}
{!useCustomLabels &&
  pollOptions.slice(0, 3).map((option, index) => (
    <label key={index} className="radio-label">
      <input
        type="radio"
        name="pollLabels"
        value={option.join(',')} // Join without space
        checked={newPollLabels.join(',') === option.join(',')}
        onChange={() => setNewPollLabels(option)}
        className="radio-input"
      />
      {option.join(', ')} {/* Display with space for readability */}
    </label>
  ))}

{/* Show more options (only if the dropdown is toggled) */}
{!useCustomLabels && showMoreOptions &&
  pollOptions.slice(3).map((option, index) => (
    <label key={index + 3} className="radio-label">
      <input
        type="radio"
        name="pollLabels"
        value={option.join(',')} // Join without space
        checked={newPollLabels.join(',') === option.join(',')}
        onChange={() => setNewPollLabels(option)}
        className="radio-input"
      />
      {option.join(', ')}
    </label>
  ))}

{/* Toggle to show or hide more options */}
{!useCustomLabels && (
  <div className="show-more-toggle" onClick={() => setShowMoreOptions(!showMoreOptions)}>
    {showMoreOptions ? '▲ Hide More Presets' : '▼ Show More Presets'}
  </div>
)}



            {/* Custom labels and confidence levels input */}
            {useCustomLabels && (
              <div className="custom-labels-inputs">
                {/* Add Main Opinion Button */}
                <button
                  className="add-main-opinion-button"
                  onClick={handleAddMainOpinion}
                  disabled={customLabels.length >= 12}
                >
                  Add Option +
                </button>

                {customLabels.map((label, optionIndex) => {
                  ensureConfidenceRefsInitialized(optionIndex); // Ensure refs for this option are initialized
                  const confidenceLevels = customConfidenceLabels?.[optionIndex] || [];

                  const numOpinions = customLabels.length;
                  const segmentSize = 360 / numOpinions;

                  // Define offsets for specific option counts
                  const offsetMap = {
                    2: 180,
                    3: 0,
                    4: 130,
                    5: 145,
                    6: 145,
                    7: 155,
                    8: 160,
                    9: 165,
                    10: 165,
                    11: 170,
                    12: 170,
                  };

                  const initialOffset = offsetMap[numOpinions] || 120;
                  const baseHue = (optionIndex * segmentSize + initialOffset) % 360;
                  const mainColor = `hsl(${baseHue}, 100%, 50%)`;

                  // Clamp opacity between 0.05 and 0.8
                  const calculateOpacity = (index, total) => {
                    const minOpacity = 0.05;
                    const maxOpacity = 0.8;
                    return minOpacity + (index / (total - 1)) * (maxOpacity - minOpacity);
                  };

                  const confidenceColors = (customConfidenceLabels[optionIndex] || []).map(
                    (_, levelIndex, arr) => {
                      const opacity = calculateOpacity(levelIndex, arr.length);
                      return `rgba(${hslToRgb(baseHue, 1, 0.5)}, ${opacity})`;
                    }
                  );

                  return (
                    <div key={optionIndex} className={`option-group option-${optionIndex + 1}`}>
                      {/* Main Option Input */}
                      <div className="main-option-container">
                        <input
                          ref={(el) => (labelRefs.current[optionIndex] = el)}
                          type="text"
                          value={label}
                          onChange={(e) => handleCustomLabelChange(optionIndex, e.target.value)}
                          onBlur={() => handleBlur(optionIndex)}
                          className={`main-option-input ${mainOptionAnimations[optionIndex] ? 'animated' : ''}`}
                          style={{ borderColor: mainColor, backgroundColor: 'transparent' }}
                        />
                        <button
                          className="delete-main-opinion-button"
                          onClick={() => handleDeleteMainOpinion(optionIndex)}
                          disabled={customLabels.length <= 3}
                        >
                          &times;
                        </button>
                      </div>

                      <div className="confidence-levels-inputs">
                        <button
                          className="add-confidence-level-button"
                          onClick={() => handleAddConfidenceLevel(optionIndex)}
                          disabled={confidenceLevels.length >= 5}
                        >
                          +
                        </button>
                        {confidenceLevels.map((confLabel, labelIndex) => (
                          <div key={labelIndex} className="confidence-input-container">
                            <input
                              ref={(el) => (confidenceRefs.current[optionIndex][labelIndex] = el)}
                              type="text"
                              value={confLabel}
                              onChange={(e) =>
                                handleCustomConfidenceLabelChange(optionIndex, labelIndex, e.target.value)
                              }
                              placeholder={`Sub Option ${labelIndex + 1}`}
                              className={`confidence-input ${
                                confidenceLevelAnimations[optionIndex]?.[labelIndex] ? 'animated' : ''
                              }`}
                              style={{
                                borderColor: mainColor,
                                backgroundColor: confidenceColors[labelIndex],
                              }}
                            />
                            <button
                              className="delete-confidence-button"
                              onClick={() => handleDeleteConfidenceLevel(optionIndex, labelIndex)}
                            >
                              &times;
                            </button>
                          </div>
                        ))}

                        {(!customConfidenceLabels[optionIndex] ||
                          customConfidenceLabels[optionIndex].length === 0) && (
                          <p className="no-confidence-labels">No confidence levels available</p>
                        )}

                        <div className="link-button-container">
                          <button
                            className={`link-confidence-button ${
                              linkConfidenceLevelsRef.current ? 'linked' : 'unlinked'
                            }`}
                            onClick={() => {
                              linkConfidenceLevelsRef.current = !linkConfidenceLevelsRef.current;
                              setForceUpdate((prev) => !prev);
                            }}
                          >
                            <i
                              className={`fa ${
                                linkConfidenceLevelsRef.current ? 'fa-link' : 'fa-unlink'
                              }`}
                              aria-hidden="true"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* Include the Color Wheel Preview */}
                <ColorWheelBuilder
                  options={colorWheelOptions}
                  calculateDynamicColors={calculateDynamicColors}
                  onNodeClick={handleNodeClick}
                />
              </div>
            )}
          </div>

          {/* Poll Category */}
          <div className="poll-category">
            <label>
              Category
              <Info infoCase="Category" />
              <select
                value={newPollCategory}
                onChange={(e) => setNewPollCategory(e.target.value)}
                className="category-select"
              >
                {categories.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </label>
          </div>

          {/* <div className="keyword-section">
  <label>
    Keywords:
    <div className="keywords-container">
      {keywords.map((keyword, index) => (
        <div key={index} className="keyword-item">
          {keyword}
          <button
            onClick={() => {
              setKeywords(keywords.filter((_, i) => i !== index));
            }}
          >
            &times;
          </button>
        </div>
      ))}
    </div>
    <input
      type="text"
      value={currentKeyword}
      onChange={(e) => setCurrentKeyword(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === ' ' || e.key === 'Enter') {
          e.preventDefault();
          if (currentKeyword.trim() && keywords.length < 10) {
            setKeywords([...keywords, currentKeyword.trim()]);
            setCurrentKeyword('');
          }
        }
      }}
      placeholder="Type a keyword and press Enter"
      className="keyword-input"
    />
  </label>
</div> */}


          {/* Voting Rules */}
          <div className="poll-auth-method">
    <label>
        Voting Rules
        <Info infoCase="Voting Rules" />
        <select
            value={voteAuthenticationMethod}
            onChange={(e) => setVoteAuthenticationMethod(parseInt(e.target.value))}
            className="auth-method-select"
            disabled={isAdvertising} // Disable when advertising is enabled
        >
            <option value={1}>One vote per person</option>
            <option value={0}>Allow multiple votes per person</option>
        </select>
    </label>
</div>

          

          

          {/* Extra Options */}
          <div className="extra-options">
          {!isAdvertising && (
    <>
        <div className="poll-create">
            <label>
                Redirect After Vote
                <Info infoCase="Redirect" />
                <input
                    type="url"
                    value={redirectLink}
                    onChange={(e) => setRedirectLink(e.target.value)}
                    placeholder="https://example.com"
                    className="redirect-input"
                    style={{ height: '25px', marginTop: '5px' }}
                />
            </label>
        </div>
        <div className="poll-create">
            <label>
                Message
                <textarea
                    value={redirectMessage}
                    onChange={(e) => {
                        if (e.target.value.length <= 70) {
                            setRedirectMessage(e.target.value);
                            setRedirectMessageError('');
                        }
                    }}
                    placeholder="Check out our website!"
                    className="redirect-message"
                    style={{ marginTop: '5px' }}
                />
                {redirectMessageError && <p className="error-message">{redirectMessageError}</p>}
            </label>
        </div>
    </>
)}


{/* CAPTCHA Checkbox */}
{visibility === 'Private' && (
            <div className="custom-checkbox">
              <input
                type="checkbox"
                id="useCaptcha"
                checked={useCaptcha}
                onChange={(e) => {
                  setUseCaptcha(e.target.checked);
                }}
              />
              <label htmlFor="useCaptcha" style={{ display: 'flex', alignItems: 'center' }}>
                Enable reCAPTCHA
                <img
                  src={captchaImage}
                  alt="Info"
                  style={{ marginLeft: '8px', width: '20px', height: '20px' }}
                />
              </label>
            </div>
          )}



            <div className="custom-checkbox">
              <input
                type="checkbox"
                id="useSchedule"
                checked={useSchedule}
                onChange={(e) => {
                  setUseSchedule(e.target.checked);
                }}
              />
              <label htmlFor="useSchedule">
                Set Poll Schedule
                <Info infoCase="Schedule" />
              </label>
            </div>

            

            {useSchedule && (
              <div className="schedule-options">
                <div className="date-picker">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => {
                        const now = new Date();
                        const selectedStartDate = date < now ? now : date;

                        // If start and end date are the same day, ensure end date is at least 1 hour later
                        if (endDate && selectedStartDate.toDateString() === endDate.toDateString()) {
                          const adjustedEndDate = new Date(endDate);
                          if (selectedStartDate >= endDate) {
                            adjustedEndDate.setHours(selectedStartDate.getHours() + 1);
                            setEndDate(adjustedEndDate);
                          }
                        }

                        setStartDate(selectedStartDate);
                      }}
                      showTimeSelect
                      timeIntervals={1} // Set time intervals to 1 minute for more precise time selection
                      dateFormat="Pp"
                      placeholderText="Select start date"
                      className="date-picker-input"
                      customInput={<CustomInput label="Start" />}
                      maxDate={endDate}
                    />
                    <button
                      type="button"
                      onClick={() => setStartDate(new Date())}
                      className="now-button"
                      style={{
                        marginLeft: '10px',
                        backgroundColor: '#1a73e8',
                        color: 'white',
                        border: 'none',
                        padding: '8px 10px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                      }}
                    >
                      Now
                    </button>
                  </div>
                </div>

                <div className="date-picker">
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => {
                      const selectedEndDate = new Date(date);

                      // Ensure end date is not before start date
                      if (startDate && selectedEndDate.toDateString() === startDate.toDateString()) {
                        if (selectedEndDate <= startDate) {
                          selectedEndDate.setHours(startDate.getHours() + 1); // Add 1 hour if the selected end time is earlier than start time
                        }
                      }

                      setEndDate(selectedEndDate);
                    }}
                    showTimeSelect
                    timeIntervals={1} // Set time intervals to 1 minute for more precise time selection
                    dateFormat="Pp"
                    placeholderText="Select end date"
                    className="date-picker-input"
                    customInput={<CustomInput label="End" />}
                    minDate={startDate}
                  />
                </div>
              </div>
            )}
          </div>

          
        </div>

        
      </div>


      {isAdvertising && (
  <div className="create-poll-content-ads">
    Promotions
    <BrandAdMockup
      ads={ads}
      setAds={setAds}
      handleAdImageUpload={handleAdImageUpload}
    />
  </div>
)}




      {/* Preview Section */}
      

      <div className="preview-container">
      <div className="Poll-item">
          
          <PreviewPoll
            pollId={null} // Since this is a preview, we won't have an ID yet
            question={pollConfig.question}
            labels={pollConfig.labels}
            logo={pollConfig.logo}
            logoFill={pollConfig.logoFill}
            hash={'preview-hash'} // Use a placeholder hash
            isLoggedIn={isLoggedIn}
            userId={userId}
            creatorId={userId}
            creatorUsername={username}
            voteAuthenticationMethod={pollConfig.voteAuthenticationMethod}
            confidenceLabels={pollConfig.confidenceLabels}
            imageAction={pollConfig.imageAction}
            roundedCorners={pollConfig.roundedCorners}
          />
        </div>
        <div className="preview-header">
            {/* <h1 className="preview-title">Preview</h1> */}
            {/* <p className="preview-description">
              Test your poll! Any votes made here won't be counted as real votes.
            </p> */}
          </div>

          

          {/* Action Buttons */}
          <div className="page-buttons">
          <button
  className="create-button"
  onClick={handleConfirmCreatePoll}
  disabled={!selectedCampaign} // Disable if no campaign is selected
>
  Create Poll!
</button>

          </div>
    </div>
    </div>
    </div>
  );
};

export default BrandCreatePoll;
