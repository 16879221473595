import React, { useState, useEffect } from "react";
import './Calculator.css';

const Calculator = ({ onClose }) => {
  const [followersToPoll, setFollowersToPoll] = useState(25000); // Default: 25,000 followers
  const [votePercentage, setVotePercentage] = useState(65); // Default: 65%
  const [ctr, setCTR] = useState(5); // Default: 5%
  const [numberOfPolls, setNumberOfPolls] = useState(5); // Default: 5 polls
  const CPC = 0.60; // Fixed CPC value

  const [votes, setVotes] = useState(0);
  const [adClicks, setAdClicks] = useState(0);
  const [rpm, setRPM] = useState(0);
  const [earnings, setEarnings] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [revenueShare, setRevenueShare] = useState(0);

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const calculateResults = () => {
    const votesCalculated = followersToPoll * (votePercentage / 100);
    const adClicksCalculated = votesCalculated * (ctr / 100);
    const rpmCalculated = CPC * ctr * 10; // CPC * CTR * 1000 / 100 = CPC * CTR * 10
    const earningsCalculated = adClicksCalculated * CPC;
    const totalEarningsCalculated = earningsCalculated * numberOfPolls;
    const revenueShareCalculated = totalEarningsCalculated / 2; // 50/50 split

    setVotes(formatNumber(votesCalculated.toFixed(0)));
    setAdClicks(formatNumber(adClicksCalculated.toFixed(0)));
    setRPM(rpmCalculated.toFixed(2));
    setEarnings(formatNumber(earningsCalculated.toFixed(2)));
    setTotalEarnings(formatNumber(totalEarningsCalculated.toFixed(2)));
    setRevenueShare(formatNumber(revenueShareCalculated.toFixed(2)));
  };

  const handleNumberInput = (value, setter, allowDecimals = false) => {
    const sanitizedValue = value.replace(allowDecimals ? /[^0-9.]/g : /[^0-9]/g, "");
    if (sanitizedValue === "" || Number(sanitizedValue) >= 0) {
      setter(sanitizedValue === "" ? "" : allowDecimals ? parseFloat(sanitizedValue) : parseInt(sanitizedValue));
    }
  };

  // Recalculate whenever inputs change
  useEffect(() => {
    calculateResults();
  }, [followersToPoll, votePercentage, ctr, numberOfPolls]);

  return (
    <div style={styles.wrapper}>
      <div style={styles.container}>
        <div style={styles.calculator}>
          <h1 style={styles.title}>Poll Revenue Calculator</h1>
          <div style={styles.inputContainer}>
            <label style={styles.label}>Followers to Poll</label>
            <input
              type="text"
              value={formatNumber(followersToPoll)}
              onChange={(e) => handleNumberInput(e.target.value.replace(/,/g, ""), setFollowersToPoll)}
              style={styles.input}
            />
          </div>
          <div style={styles.inputContainer}>
            <label style={styles.label}>Vote Percentage (%)</label>
            <input
              type="text"
              value={votePercentage}
              onChange={(e) => handleNumberInput(e.target.value, setVotePercentage)}
              style={styles.input}
            />
          </div>
          <div style={styles.inputContainer}>
            <label style={styles.label}>CTR (Click-Through Rate %)</label>
            <input
              type="text"
              value={ctr}
              onChange={(e) => handleNumberInput(e.target.value, setCTR)}
              style={styles.input}
            />
          </div>
          <div style={styles.inputContainer}>
            <label style={styles.label}>Number of Polls</label>
            <input
              type="text"
              value={numberOfPolls}
              onChange={(e) => handleNumberInput(e.target.value, setNumberOfPolls)}
              style={styles.input}
            />
          </div>

          <div style={styles.results}>
            <h2 style={styles.resultsTitle}>Calculation Breakdown</h2>
            <div style={styles.receipt}>
              <p style={styles.receiptItem}>
                <span>Votes:</span>
                <span>{votes}</span>
              </p>
              <p style={styles.receiptItem}>
                <span>Ad Clicks:</span>
                <span>{adClicks}</span>
              </p>
              <p style={styles.receiptItem}>
                <span>RPM:</span>
                <span>${rpm}</span>
              </p>
              <p style={styles.receiptItem}>
                <span>Earnings per Poll:</span>
                <span>${earnings}</span>
              </p>
              <p style={styles.receiptItem}>
                <span>Gross Earnings:</span>
                <span>${totalEarnings}</span>
              </p>
              <p style={styles.receiptTotal}>
                <span>Your Earnings:</span>
                <span>${revenueShare}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  calculator: {
    backgroundColor: "#151515",
    padding: "20px",
    borderRadius: "10px",
    color: "#FFF",
    maxWidth: "450px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
  },
  title: {
    textAlign: "center",
    marginBottom: "20px",
  },
  inputContainer: {
    marginBottom: "15px",
  },
  label: {
    display: "block",
    marginBottom: "5px",
  },
  input: {
    width: "100%",
    padding: "8px",
    borderRadius: "5px",
    border: "1px solid #444",
    backgroundColor: "#222",
    color: "#FFF",
  },
  results: {
    marginTop: "20px",
    textAlign: "center",
  },
  resultsTitle: {
    marginBottom: "15px",
    fontSize: "1.2em",
  },
  receipt: {
    backgroundColor: "#003aff",
    padding: "15px",
    borderRadius: "10px",
  },
  receiptItem: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  receiptTotal: {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: "bold",
    fontSize: "1.1em",
    marginTop: "15px",
    borderTop: "1px solid white",
    paddingTop: "10px",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: "transparent",
    color: "#FFF",
    fontSize: "1.5em",
    border: "none",
    cursor: "pointer",
  },
};

export default Calculator;
