import React, { useState } from 'react';
import './About.css';
import DesktopBG from '../assets/bg.png';
import MobileBG from '../assets/bg.png';
import ScrollingPollsVertical from './ScrollingPollsVertical';
import ScrollingPollsVerticalReverse from './ScrollingPollsVerticalReverse';
import { useNavigate, Link } from 'react-router-dom';



import Logo from '../assets/logo.png';
import HeroImg from '../assets/Hero.png';
import YouTubeIcon from '../assets/youtube.png';
import FacebookIcon from '../assets/facebook.png';
import InstagramIcon from '../assets/instagram.png';
import LinkedInIcon from '../assets/linkedin.png';
import PinterestIcon from '../assets/pinterest.png';
import RedditIcon from '../assets/reddit.png';
import SnapchatIcon from '../assets/snapchat.png';
import TikTokIcon from '../assets/tiktok.png';
import XIcon from '../assets/x.png';
import OpinionsImg from '../assets/opinions.png';
import FlexibilityImg from '../assets/flexibility.png';
import HandledImg from '../assets/handled.png';

const About = ({ isLoggedIn }) => {
    const isDesktop = window.innerWidth >= 768;

    const backgroundStyle = {
      backgroundImage: `url(${isDesktop ? DesktopBG : MobileBG})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: -1,
    };

    const navigate = useNavigate();

    const goToSignUp = () => {
      navigate('/signup');
    };
    const goToDashboard = () => {
      navigate('/dashboard/main');
    };
    const goToFeed = () => {
        navigate('/feed');
      };

    return (
      
        <div className="about-page-wrapper">
                <div style={backgroundStyle}></div>
            <div className="about-container">
                <div className="combined-sections-container">
                <section className="about-para-section">
  <div className="text-content">
    <h2>About</h2>
    <p>
  <strong>OPINARI</strong> | <em>oh-pin-r-ee</em> | [verb]  
  • The Latin word meaning “to be of the opinion” refers to the act of forming or holding an opinion or belief about something.  
  <br /><br />
  • Is a 100% free-to-use platform that shares ad revenue and purchase commissions 50/50 with our users. 
  <br /><br />
  • Pays you whenever anyone views/clicks on a personalized ad, or purchases a relevant item displayed based entirely on their poll answer.  
  <br /><br />
  • Comes with a fully featured dashboard where revenue, clicks, impressions, answers and so much more can be accessed.  
  <br /><br />
  • Uses a revolutionary new color wheel that facilitates lightning-fast answers by simply sliding your thumb across any color that triggers the answer desired.  
  <br /><br />
  • Polls are a single link that can be shared everywhere and include a dedicated QR-Code for any display opportunities.  
  <br /><br />
  • Partners with Taboola™ - the #1 open web advertising platform trusted by the world’s leading brands and served over 600 million times daily.  
  <br /><br />
  • We are also partners with Amazon®, the World’s #1 shopping destination and we provide an affiliate commission whenever anyone purchases anything after they’ve voted.  
  <br /><br />
  • Opens the creative floodgates and guides new content creation because you now understand exactly what your audience wants more of.  
  <br /><br />
  <button
      onClick={isLoggedIn ? goToDashboard : goToSignUp}
      className="cta-button"
    >
      {isLoggedIn ? 'Go to Dashboard' : 'Start Free'}
  </button>    
</p>

  </div>
</section>

  

 
  




                    
                </div>
            </div>

            <footer className="footer">
        <div className="footer-container">
          <div className="footer-logo-section">
            <img src={Logo} alt="Logo" className="footer-logo" />
          </div>

          <div className="footer-links">
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/about">About</a></li>
              <li><a href="/contact">Contact</a></li>
              <li><a href="/FAQs">FAQs</a></li>
            </ul>
          </div>

          <div className="footer-socials">
            <img src={YouTubeIcon} alt="YouTube" />
            <img src={FacebookIcon} alt="Facebook" />
            <img src={InstagramIcon} alt="Instagram" />
            <img src={LinkedInIcon} alt="LinkedIn" />
            <img src={TikTokIcon} alt="TikTok" />
          </div>
        </div>

        <div className="footer-bottom">
          <p>&copy; 2025 OPINARI. All rights reserved.</p>
        </div>
      </footer>
        </div>
    );
};

export default About;