import React from "react";
import "./Onboarding.css";
import { useNavigate } from "react-router-dom";

const Onboarding = ({ username, headline, subheadline }) => {
  const navigate = useNavigate();

  const suggestions = [
    {
      question: "What should my next video be about?",
      answers: ["Tech", "Gaming", "Vlogging", "Tips & Tricks"],
      confidenceLevels: [
        ["Smartphones", "Laptops", "AI"],
        ["Let's Play", "Game Reviews", "Competitive"],
        ["Day in the life", "Travel", "Pranks"],
        ["Tech", "Business", "Travel"],
      ],
    },
    {
      question: "Which product should I review next?",
      answers: ["Smartphone", "Laptop", "Headphones", "Gaming Console"],
      confidenceLevels: [
        ["Budget", "Mid-range", "Flagship"],
        ["Entry-level", "Productive", "Gaming"],
        ["Casual", "Audiophile", "Studio"],
        ["Sony", "Microsoft", "Steam"],
      ],
    },
    {
      question: "What time should I go live?",
      answers: ["Morning", "Afternoon", "Evening", "Late Night"],
      confidenceLevels: [
        ["6-9 AM", "9-11 AM", "11 AM - 1 PM"],
        ["1-3 PM", "3-5 PM", "5-7 PM"],
        ["7-9 PM", "9-11 PM", "11 PM - 1 AM"],
        ["1-3 AM", "3-5 AM", "5-6 AM"],
      ],
    },
    {
      question: "What type of giveaways do you prefer?",
      answers: ["Cash", "Tech Gadgets", "Gift Cards", "Merch"],
      confidenceLevels: [
        ["Small Amount", "Medium", "Large Prize"],
        ["Smartphones", "Laptops", "Gaming PC's"],
        ["Steam", "Apple", "Amazon"],
        ["Stickers", "T-Shirts", "Hats"],
      ],
    },
    {
      question: "What type of merch should I drop?",
      answers: ["T-Shirts", "Hoodies", "Accessories", "Limited Edition"],
      confidenceLevels: [
        ["Graphic Tees", "Minimalist", "Bold Designs"],
        ["Pullover", "Zip-Up", "Oversized"],
        ["Phone Cases", "Stickers", "Hats"],
        ["Signed", "Limited Stock", "Exclusive Colors"],
      ],
    },
    {
      question: "Who should I collab with next?",
      answers: ["Tech YouTuber", "Gaming Streamer", "Vlogger", "Comedian"],
      confidenceLevels: [
        ["Small Creator", "Growing Creator", "Top Creator"],
        ["Casual Gamer", "Competitive Gamer", "Pro Player"],
        ["Daily Vlogger", "Travel Vlogger", "Lifestyle Vlogger"],
        ["Funny Clips", "Comedy Sketches", "Stand-up"],
      ],
    },
  ];
  





  const handleSuggestionClick = (suggestion) => {
    navigate("/dashboard/main?openTab=Create Poll", {
      state: {
        question: suggestion.question,
        customLabels: suggestion.answers,
        customConfidenceLabels: suggestion.confidenceLevels, // Ensure this is passed
      },
    });
  };
  
  
  
  

  return (
    <div className="Onboarding-container">
      <div className="Onboarding-card">
        <h1 className="Onboarding-welcome">{headline || `${username}, Welcome Aboard!`}</h1>
        <p className="Onboarding-subtitle">
          {subheadline ||
            "Start by creating a poll, then share it with your audience to start earning!"}
        </p>
        <button
          className="Onboarding-create-button"
          onClick={() => navigate("/dashboard/main?openTab=Create Poll")}
        >
          Create Poll +
        </button>

        <p className="Onboarding-suggestion">Not sure what to ask? How about...</p>

        <div className="Onboarding-suggestions-list">
  {suggestions.map((suggestion, index) => (
    <button
      key={index}
      className="Onboarding-suggestion-item"
      onClick={() => handleSuggestionClick(suggestion)} // Pass as a function
    >
      {suggestion.question}
      <span className="Onboarding-arrow">→</span>
    </button>
  ))}
</div>
      </div>
    </div>
  );
};

export default Onboarding;
