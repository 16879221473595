import React, { useState, useEffect } from "react";
import "./Billing.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle, faLock } from "@fortawesome/free-solid-svg-icons";
import OptionsModal from "./OptionsModal";
import CSVViewer from "./CSVViewer";
import PayPalLogo from "../assets/PayPal.png";
import Chip from "../assets/Chip.png";

function UpdatePaypalEmail({ userId, name, updatePaypalConnection }) {
  const [paypalEmail, setPaypalEmail] = useState("");
  const [savedPaypalEmail, setSavedPaypalEmail] = useState("");
  const [totalRevenue, setTotalRevenue] = useState(0.0);
  const [message, setMessage] = useState("");
  const [isEditable, setIsEditable] = useState(true); // Editable if no email is set
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [affiliateCommission, setAffiliateCommission] = useState(0);


  useEffect(() => {
    const fetchUserData = async () => {
        try {
            const response = await fetch(`/api/user/${userId}/revenue`);
            const result = await response.json();

            if (response.ok) {
                if (result.paypalEmail) {
                    setPaypalEmail(result.paypalEmail);
                    setSavedPaypalEmail(result.paypalEmail);
                    setMessage(`Connected to: ${result.paypalEmail}`);
                    setIsEditable(false);
                } else {
                    setMessage("PayPal account not connected");
                    setIsEditable(true);
                }
            } else {
                setMessage("Error fetching revenue and PayPal details");
            }

            // Fetch poll revenue
            const revenueResponse = await fetch(`/api/user/${userId}/poll-revenue`);
            const revenueData = await revenueResponse.json();
            if (revenueResponse.ok) {
                const revenue = revenueData.totalRevenue?.$numberDecimal
                    ? parseFloat(revenueData.totalRevenue.$numberDecimal)
                    : parseFloat(revenueData.totalRevenue || 0);
                setTotalRevenue(revenue);
            }

            // Fetch affiliate commission
            const affiliateResponse = await fetch(`/api/affiliate-stats/${userId}`);
            if (affiliateResponse.ok) {
              const affiliateData = await affiliateResponse.json();
              setAffiliateCommission(parseFloat(affiliateData.totalAffiliateCommission) || 0);
          }

        } catch (error) {
            console.error("Error fetching user data:", error);
            setMessage("An error occurred while fetching data");
        }
    };

    fetchUserData();
}, [userId]);


  const handleSavePaypalEmail = async () => {
    if (!paypalEmail) {
      setMessage("Please enter a valid PayPal email address.");
      return;
    }

    try {
      const response = await fetch("/api/user/paypal-email", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId, paypalEmail }),
      });

      const result = await response.json();
      if (response.ok) {
        setSavedPaypalEmail(paypalEmail);
        setIsEditable(false);
        setMessage(`PayPal connected: ${paypalEmail}`);
        updatePaypalConnection(true); // Notify parent of updated state
      } else {
        setMessage(result.message || "Failed to update PayPal email.");
      }
    } catch (error) {
      console.error("Error updating PayPal email:", error);
      setMessage("An error occurred while updating PayPal email.");
    }
  };

  const handlePasswordVerification = async (values) => {
    try {
      const response = await fetch("/auth/verify-password", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId, password: values.password }),
      });

      const result = await response.json();

      if (response.ok) {
        setIsModalOpen(false);
        setIsEditable(true); // Enable editing after password verification
        setMessage("Password verified successfully.");
      } else {
        setMessage(result.error || "Password verification failed.");
      }
    } catch (error) {
      console.error("Error verifying password:", error);
      setMessage("An error occurred during password verification.");
    }
  };

  return (
    <div className="paypal-email__container">
      <div className="paypal-email__form-section">
        <div className="paypal-email__wrapper">
          <div className="paypal-email__left-col">
            <div className="paypal-email__cards">
              <div className="paypal-email__button-wrapper">
                <h2>
                  {savedPaypalEmail ? (
                    <>
                      PayPal Connected <FontAwesomeIcon icon={faCheckCircle} style={{ color: "#6adb6a" }} />
                    </>
                  ) : (
                    <>
                      Connect PayPal <FontAwesomeIcon icon={faExclamationCircle} style={{ color: "#ff4d4d" }} />
                    </>
                  )}
                </h2>
                <div className="paypal-input-wrapper">
                  <input
                    type="email"
                    placeholder="Enter PayPal email"
                    value={paypalEmail}
                    onChange={(e) => setPaypalEmail(e.target.value)}
                    className="paypal-email-input"
                    disabled={!isEditable}
                  />
                  <button
                    onClick={
                      isEditable
                        ? handleSavePaypalEmail
                        : savedPaypalEmail
                        ? () => setIsModalOpen(true)
                        : handleSavePaypalEmail
                    }
                    className="paypal-save-button"
                  >
                    {isEditable ? "Save PayPal Email" : "Change"}
                    {!isEditable && <FontAwesomeIcon icon={faLock} />}
                  </button>
                </div>
                {message && <p className="signup-page__message">{message}</p>}
              </div>

              <div className={`credit-card ${savedPaypalEmail ? "" : "credit-card--disabled"}`}>
    <div className="credit-card__header">
        <img src={PayPalLogo} alt="PayPal Logo" className="credit-card__paypal-logo" />
    </div>
    <div className="credit-card__content">
        <h3>{savedPaypalEmail ? name : "Not Connected"}</h3>
        <p>Gross Revenue: ${totalRevenue.toFixed(2)}</p>
        <div className="checkout-divider" />
        <p>Rev Share (50%): -${(totalRevenue / 2).toFixed(2)}</p>

        <p>Affiliate Commission: +${affiliateCommission.toFixed(2)}</p>
        <p className="net-balance">
            Payout Balance: ${(totalRevenue / 2 + affiliateCommission).toFixed(2)}
        </p>
    </div>
    <div className="credit-card__footer">
        <img src={Chip} alt="Card Chip" className="credit-card__chip" />
    </div>
</div>

            </div>

            <div className="paypal-email__csv-viewer">
              <CSVViewer userId={userId} />
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Password Verification */}
      <OptionsModal
        title="Verify Password"
        fields={[{ name: "password", label: "Opinari Password", type: "text" }]}
        isOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onSave={handlePasswordVerification}
        saveButtonText="Confirm"
      />
    </div>
  );
}

export default UpdatePaypalEmail;
