import React, { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "./Revenue.css";

const Revenue = ({ userId }) => {
  const [polls, setPolls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortOption, setSortOption] = useState("highest");
  const [lastUpdated, setLastUpdated] = useState(null);
  const navigate = useNavigate();

  /**
   * Fetch user polls that already contain revenue and Taboola data
   */
  const fetchUserPolls = async () => {
    console.log("Fetching user polls with revenue & Taboola data...");
    setLoading(true);
    try {
      const response = await fetch(`/api/user/${userId}/polls`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch polls: ${response.status}`);
      }

      const { polls } = await response.json();
      console.log("Fetched User Polls:", polls);
      setPolls(polls);

      // Determine the latest revenue update timestamp
      const latestUpdate = polls
        .map((poll) => new Date(poll.lastRevenueUpdate || 0))
        .reduce((latest, current) => (current > latest ? current : latest), new Date(0));

      setLastUpdated(latestUpdate !== new Date(0) ? latestUpdate : null);
    } catch (error) {
      console.error("Error fetching polls:", error.message);
    } finally {
      setLoading(false);
    }
  };

  /**
   * On mount, fetch polls with Taboola & revenue data
   */
  useEffect(() => {
    fetchUserPolls();
  }, [userId]);

  /**
   * Calculate total earnings
   */
  const calculateTotalEarnings = () => {
    return polls.reduce((total, poll) => total + parseFloat(poll.revenue || 0), 0);
  };

  /**
   * Sorting logic for polls
   */
  const sortedPolls = [...polls].sort((a, b) => {
    const totalA = parseFloat(a.revenue || 0);
    const totalB = parseFloat(b.revenue || 0);
    return sortOption === "highest" ? totalB - totalA : totalA - totalB;
  });

  return (
    <div className="revenue">
      <div className="filters">
        <select value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
          <option value="highest">Highest Earning</option>
          <option value="lowest">Lowest Earning</option>
        </select>
        
        {/* Last Updated Timestamp */}
        {lastUpdated && (
          <p className="last-updated">
            Updated: {lastUpdated.toLocaleString()}
          </p>
        )}
      </div>

      <div className="total-earnings">
        <h1>
          Estimated Gross Earnings:{" "}
          <span className="earnings-amount">${calculateTotalEarnings().toFixed(2)}</span>
        </h1>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="card-grid">
          {sortedPolls.map((poll, index) => (
            <div key={index} className={`revenue-card ${poll.revenue === 0 ? "card-zero-revenue" : ""}`}>
              <div className="redirect-icon-container" onClick={() => navigate(`/poll/${poll.hash}`)}>
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="redirect-icon" />
              </div>

              <h2 className="poll-rev-question">“{poll.question}”</h2>

              {/* Big Revenue Number */}
              <div className="big-revenue">
                <span className={`big-revenue-value ${poll.revenue === 0 ? "grey-revenue" : ""}`}>
                  ${parseFloat(poll.revenue || 0).toFixed(2)}
                </span>
              </div>

              {/* Additional Stats from Taboola */}
              <div className="stats-container">
                <p>
                  <strong>Impressions:</strong> {poll.taboola?.impressions?.toLocaleString() || 0}
                </p>
                <p>
                  <strong>Page Views:</strong> {poll.taboola?.page_views?.toLocaleString() || 0}
                </p>
                <p>
                  <strong>Ad RPM:</strong> ${poll.taboola?.ad_rpm?.toFixed(2) || "0.00"}
                </p>
                <p>
                  <strong>Bounce Rate:</strong> {poll.taboola?.bounce_rate != null ? `${poll.taboola.bounce_rate.toFixed(2)}%` : "N/A"}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Revenue;
