import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Poll from './Poll';
import logger from './logger';
import PollSuccessModal from './PollSuccessModal';
import PoweredBy from '../assets/PoweredBy.png';



const StandalonePoll = ({ isLoggedIn, userId, username }) => {
  const { hash } = useParams();
  const location = useLocation(); // To access query parameters
  const [poll, setPoll] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false); // State for showing success modal
  const navigate = useNavigate();


const poweredByWrapperStyle = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10px',
};

const poweredByStyle = {
  height: '60px', // Set the width of the powered by image
  display: 'block',
  marginTop: '10px',
  marginBottom: '30px'
};



  useEffect(() => {
    logger.log('Fetching poll with hash:', hash);
    fetch(`/polls/${hash}`)
      .then(response => response.json())
      .then(data => {
        logger.log('Poll data:', data);
        setPoll(data.poll);
        setLoading(false);

        // Check if we have the 'created' query param to show the success modal
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get('created') === 'true') {
          setShowSuccessModal(true); // Show success modal if poll was just created
        }
      })
      .catch(error => {
        logger.error('Error fetching poll:', error);
        setLoading(false);
      });
  }, [hash, location.search]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!poll) {
    return <div>This poll has been deleted.</div>;
  }

  return (

    <div className="StandalonePollWrapper">
      {showSuccessModal && poll && (
        <PollSuccessModal
          pollLink={`${window.location.origin}/poll/${hash}`} // Use full URL
          onClose={() => setShowSuccessModal(false)}
          isTemporary={poll.isTemporary}
        />
      )}
      <div className="StandalonePoll" style={{ padding: '20px', position: 'relative' }}>
        {/* Overlay for 'Under Review' */}
        {!poll.approved && (
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgb(15 15 15 / 55%)', // Dark overlay
              zIndex: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              fontSize: '24px',
              borderRadius: '10px', // Match the overall poll design
            }}
          >
            Poll Under Review
          </div>
        )}
  
        <div className="Poll-item" style={{ opacity: poll.approved ? 1 : 0.5 }}>
          <Poll
            creatorId={poll.creator._id} // Extract _id from the creator object
            pollId={poll._id}
            question={poll.question}
            labels={poll.labels}
            logo={poll.logo || "/logo-poll.png"} // Pass the logo from the poll object or use the default if not available
            logoFill={poll.logoFill}
            votes={poll.votes}
            hash={hash}
            creatorUsername={poll.creatorUsername}
            isLoggedIn={isLoggedIn}
            userId={userId}
            username={username}
            voteAuthenticationMethod={poll.voteAuthenticationMethod}
            confidenceLabels={poll.confidenceLabels}
            imageAction={poll.imageAction}
            roundedCorners={poll.roundedCorners}
            startDate={poll.startDate}
            endDate={poll.endDate}
            scheduledPoll={poll.scheduledPoll}
            useCaptcha={poll.useCaptcha}
            totalVotes={poll.totalVotes}
            isTemporary={poll.isTemporary}
            // scrollToBottom={scrollToBottom} // Pass scroll function to Poll
            isLegacyPoll={poll.isLegacyPoll}
            isAdvertising={poll.isAdvertising}
            category={poll.category}
            approved={poll.approved}
            pollType={poll.pollType}
            ads={poll.ads}
          />
        </div>
  
        <a href="/" style={{ textDecoration: 'none' }}>
          <div style={poweredByWrapperStyle}>
            <img
              src={PoweredBy}
              alt="Powered by Opinari"
              style={poweredByStyle}
            />
          </div>
        </a>
      </div>
    </div>
  );
  
};

export default StandalonePoll;
